import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Kenny = () => {
  return (
    <Layout>
      <Head title="Kenny DeForest" />
      <h3>Kenny DeForest</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        New York
      </h4>
      <p>
        <OutboundLink href="https://www.kennydeforest.com/">
          Website
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.youtube.com/channel/UCj-7aceRKtJibzHhh-MrOpA">
          YouTube
        </OutboundLink>
      </p>
      <p>
        Kenny DeForest is a stand-up comedian, writer, and actor best known for
        his appearances on Comedy Central, Late Night with Seth Meyers, The Late
        Late Show with James Corden, HBO’s “Crashing”, & TruTV’s “Friends of the
        People.”
      </p>
      <p>
        In 2017, he recorded his debut stand-up album "B.A.D. Dreams" with
        Comedy Dynamics, which debuted #1 on iTunes, and in 2015 he was named
        one of Comedy Central’s “Comics to Watch." In 2018, he was invited to
        perform in the prestigious Just For Laughs Comedy Festival in Montreal
        as a “New Face” where he was named "Best of the New Faces" by The
        Village Voice. In 2016, he was included in the "50 Funniest People in
        Brooklyn" by Brooklyn Magazine.
      </p>
      <p>
        With a wry grin and a gentle heart, DeForest guides the audience on a
        hilarious journey of relatable self-discovery by sharing his
        ever-evolving jaded-optimistic POV on what it means to be alive on this
        planet at this moment using stories, observations, personal anecdotes,
        and social commentary, including the occasional rant, but he rarely
        comes off as divisive because his core belief is that, no matter how
        much we disagree, beneath it all, when we stop taking ourselves too
        seriously to laugh at ourselves, and we put our phones down, we are just
        a bunch of people doing the best we can with the tools we have and we
        all have way more in common than the billionaires manipulating us to
        hate each other so they can go to space with the money they extracted by
        profiting from our fear. Or something like that.
      </p>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/sTBT7wfaJSg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Kenny;
